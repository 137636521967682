let robotoLight1 = require('../fonts/roboto-light.ttf');
let robotoLight2 = require('../fonts/roboto-light.woff');
let robotoLight3 = require('../fonts/roboto-light.woff2');

let robotoReg1 = require('../fonts/roboto-regular.ttf');
let robotoReg2 = require('../fonts/roboto-regular.woff');
let robotoReg3 = require('../fonts/roboto-regular.woff2');

let robotoBold1 = require('../fonts/roboto-bold.ttf');
let robotoBold2 = require('../fonts/roboto-bold.woff');
let robotoBold3 = require('../fonts/roboto-bold.woff2');