import '../scss/main.scss';
//replacement for babel-polyfill to use if polyfills are needed
// import 'core-js/stable';
// import "regenerator-runtime/runtime";

import $ from 'jquery';
import browserUpdate from 'browser-update';
import './fonts.js';
import 'bootstrap';

(function ($) {
    var mqBreakpoints = {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        },
        $window = $(window),
        windowWidth = $window.innerWidth();


    /*
     * Function
     * Expand aria-controls elements
     */
    var ariaControls = function () {
        if ($('.js-aria-control').length > 0) {
            $(document).on('click', '.js-aria-control', function (e) {
                e.preventDefault();

                var $_this = $(this),
                    $_targetEl = $('#' + $_this.attr('aria-controls')),
                    state = $_this.attr('aria-expanded') !== 'false';

                $_this.attr('aria-expanded', !state);
                $_targetEl.attr('aria-hidden', state);
            });
        }
    };


    /*
     * Function
     * Check visibility for ARIA controlled element and add attributes (hidden and expanded)
     */
    var checkVisibility = function (id, breakpoint) {
        var $_el = $('#' + id),
            controller = $('[aria-controls=' + id + ']');

        if ($('window').innerWidth() <= breakpoint) {
            $_el.attr('aria-hidden', true);
            controller.attr('aria-expanded', false);
        } else {
            $_el.attr('aria-hidden', false);
            controller.attr('aria-expanded', true);
        }
    };


    /*
     * Function
     * When window is resized, re-check visibility
     */
    var watchVisibility = function () {
        // If orientation change (for mobile and tablet)
        window.addEventListener("orientationchange", function () {
            // Change windowWidth
            windowWidth = window.innerWidth();
        }, false);

        $(window).resize(function () {
            // Check if width really change (mobile consider scrolling as a width change)
            if ($window.innerWidth() !== windowWidth) {
                checkVisibility('main-nav', mqBreakpoints.tablet);
            }
        });
    };

    function placeholderShown(self) {
        let shown = 'placeholder-shown';
        let hasValue = !!self.value;
        self.classList.toggle(shown, !hasValue);
    }

    function initPlaceholder() {
        let inputs = document.querySelectorAll('input:not([type="checkbox"]):not([type="radio"]):not(.form-number)', 'textarea');
        inputs.forEach(input => {
            placeholderShown(input);
            input.addEventListener('change', e => placeholderShown(e.target));
        });

        document.addEventListener('keyup', (e) => {
            if (e.target.classList.contains('form-control')) {
                placeholderShown(e.target);
            }
        });

        document.addEventListener('DOMAutocomplete', (e) => {
            if (e.target.classList.contains('form-control')) {
                placeholderShown(e.target);
            }
        });

    }

    $(document).ready(function () {
        ariaControls();
        checkVisibility('main-nav', mqBreakpoints.lg);
        watchVisibility();

        browserUpdate({
            required: {
                e: -2,
                f: -3,
                o: -3,
                s: 10.1,
                c: "64.0.3282.16817",
                samsung: 7.0,
                vivaldi: 1.2
            },
            container: document.body
        });
        initPlaceholder();

        $('.nav-link').on('click', function () {
            if ($(window).width() <= mqBreakpoints.sm) {
                $('.navbar-toggler').click();
            }
        });

        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function (event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                    &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    var targetTop = target.offset().top - 100;
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: targetTop
                        }, 1000, function () {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) { // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            }
                            ;
                        });
                    }
                }
            });

        $("#contactForm").on('submit', (function (e) {
            e.preventDefault();
            $("#status-message").hide();
            $('#send-button').attr("disabled", true);
            $('#loader-icon').show();
            $.ajax({
                url: "contact.php",
                type: "POST",
                dataType: 'json',
                data: {
                    "firstname": $('input[name="firstname"]').val(),
                    "lastname": $('input[name="lastname"]').val(),
                    "company": $('input[name="company"]').val(),
                    "object": $('input[name="object"]').val(),
                    "email": $('input[name="email"]').val(),
                    "phone": $('input[name="phone"]').val(),
                    "message": $('textarea[name="message"]').val(),
                    "g-recaptcha-response": $('input[id="recaptchaResponse"]').val()
                },
                success: function (response) {
                    $(".form-group").removeClass('error');
                    $(".form-group .form-text").html('');
                    $("#status-message").show();
                    $('#loader-icon').hide();
                    if (response.type == "error") {
                        $('#send-button').attr("disabled", false);
                        $("#status-message").attr("class", "error");
                        var errors = response.errors;
                        $.each(errors, function (id, error) {
                            $.each(error, function (id, message) {
                                $("#" + id + "Input ~ .form-text").html(message);
                                $("#" + id + "Input").addClass('error');
                            });
                        });
                        grecaptcha.reset();
                    } else if (response.type == "message") {
                        $(".form-control").removeClass('error');
                        $('#send-button').attr("disabled", true);
                        $("#status-message").attr("class", "success");
                        $("#status-message").html(response.text);
                    }
                },
                error: function () {
                    alert('error');
                    $("#status-message").html("Error");
                }
            });
        }));
    });

})($);
